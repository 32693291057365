<template>
    <section>
      <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="150px" style="width:95%;overflow:auto">
        <el-form-item label="商户名称" prop="name">
          <el-input placeholder="请输入商户名称" v-model="form.name" ></el-input>
        </el-form-item>
        <el-form-item label="所属省/市/区/街道/社区" prop="regionId">
          <el-cascader
            clearable
            placeholder="请选择"
            v-model="form.regionId"
            :options="regionTree"
            :props="{ expandTrigger: 'click',value:'id',label:'name',children:'childrenList'}"
            @change="chooseCityStreet"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" prop="address">
          <el-input placeholder="请输入详细地址" v-model="form.address"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="phone">
          <el-input placeholder="请输入联系电话" v-model="form.phone"></el-input>
        </el-form-item>
        <el-form-item label="商户代码" prop="code">
          <el-input placeholder="请输入商户代码" v-model="form.code"></el-input>
        </el-form-item>
        <el-form-item label="微信商户号" prop="mchId">
          <el-input placeholder="请输入微信商户号" v-model="form.mchId"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="description">
          <el-input type="textarea" placeholder="请输入备注" v-model="form.description"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="close('form')">取消</el-button>
          <el-button type="primary" @click="handleSubmit('form')" :plain='true'>立即提交</el-button>
        </el-form-item>
      </el-form>
    </section>
  </template>
  
  <script>
  import { getRegionTree,getParentTree,addMerchant,editMerchant } from '@/api/public.js';
  import { removeChildren,getCascaderLabel } from '@/api/publicFun.js';
  export default {
    props:['row'], // 父组件携带参数
    data() {
      return {
        form:{ // 表单数据
            name:"",
            regionId:"",
            address:"",
            code:"",
            description:"",
        },
        rules: { // 表单验证
          name:[
            { required: true, message:'商户名称不得为空', trigger: 'blur'}
          ],
          code:[
            { required: true, message:'商户代码不得为空', trigger: "blur"}
          ],
          regionId:[
            { required: true, message:'所属行政区不得为空', trigger: "blur"}
          ],
          address:[
            { required: true, message:'详细地址不得为空', trigger: "blur"}
          ],
          
        },
        regionTree:[], // 行政区选项
        pcaInfo:{}, // 所选行政区信息
      };
    },
  
    components: {},
  
    computed: {},
  
    mounted() {
      if(this.row){
        this.form = {...this.row};
        this.getParentPca(this.form.regionId);
      }
      this.getPCA();
    },
  
    methods: {
      // 关闭弹窗
      close(form){
         this.$emit('close');
      },
      // 获取省市区联动数据
      async getPCA(){
        await getRegionTree().then(res=>{
            if(res.success){
              this.regionTree = res.data;
              this.regionTree.forEach(element => {
                  element = removeChildren(element);
              });
            }else{
              this.regionTree = [];
            }
        })
      },
      // 省市区：通过子级id获取父级id
      async getParentPca(id){
        let param = new URLSearchParams();
        param.append("param", id);
        await getParentTree(param).then(res=>{
          if(res.success){
            let all = res.data;
            this.digui(all,[],"")
          }
        })
      },
      // 递归
      digui(obj,regionArr,address){
        regionArr.unshift(obj.id);
        address = obj.name + address;
        if(obj.parent){
          this.digui(obj.parent,regionArr,address);
        }else{
          this.form.regionId = regionArr;
          this.pcaInfo = address;
        }
      },
      // 选择省市区
      chooseCityStreet(e){
        if(e){
          let text = "";
          this.pcaInfo = getCascaderLabel([...e],this.cityOptions,text);
        }
      },
      // 新增
      async add(){
        await addMerchant({param:this.form}).then(res=>{
          if(res.success){
            this.$message({
              message:'提交成功',
              type:'success'
            });
            this.$emit('close');
          }else{
            this.$message({
              message:res.msg,
              type:'fail'
            });
          }
        })
      },
      // 编辑
      async edit(){
        await editMerchant({param:this.form}).then(res=>{
          if(res.success){
            this.$message({
              message:'提交成功',
              type:'success'
            });
            this.$emit('close');
          }else{
            this.$message({
              message:res.msg,
              type:'fail'
            });
          }
        })
      },
      // 提交
      handleSubmit(form) {
         console.log(this.form,990)
         this.$refs["form"].validate(valid => {
            if (valid) {
                this.$confirm('确认提交吗？', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(()=>{
                  let inde = this.form.regionId.length - 1;
                  this.form.regionId = this.form.regionId[inde];
                  if(this.row){
                    this.edit();
                  }else{
                    this.add();
                  }
                })
            }else{
              return false;
            }
        });
      },
    }
  };
  </script>
  <style lang="scss" scoped>
  // 防止地图自动完成的对象被遮挡
  .tangram-suggestion {
      z-index: 9999 !important;
    }
   .el-drawer{
     overflow:auto !important;
   }
   /deep/ .el-form-item__content{
    display: flex;
   }
   .map{
    border: 1px solid #dcdfe6;
    background: #fff;
    border-radius: 4px;
    width: 80px;
   }
   .avatar-uploader .el-upload {
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      border: 1px dashed #ccc;
      border-radius: 50%;
      overflow:hidden;
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  </style>
  