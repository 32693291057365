<template>
    <section class="outContainer">
      <!-- 搜索 -->
      <div class="search">
          <el-input class="each" placeholder="请输入商户名称" v-model="searchInfo.param.name"></el-input>
          <el-button size="mini" type="primary" icon="el-icon-search" @click="handleSearch('search')">搜索</el-button>
          <el-button size="mini" type="primary" icon="el-icon-close"  @click="handleSearch('clear')">清空搜索条件</el-button>
          <el-button size="mini" @click="handleAdd(null)">新增</el-button>
      </div>
      <div class="container tableContainer">
          <!-- 表格数据 -->
          <el-table
            :data="tableList"
            style="width: 100%"
            height="100%"
            class="table"
            fit
          >
          <el-table-column align='center' label="商户名称" prop="name">
          </el-table-column>
          <el-table-column align='center' label="商户代码" prop="code">
          </el-table-column>
          <el-table-column align='center' label="微信商户号" prop="mchId">
            <template slot-scope="scope">
              <p>{{ scope.row.mchId || scope.row.mchId == 0 ? scope.row.mchId : '--' }}</p>
            </template>
          </el-table-column>
          <el-table-column align='center' label="地址" prop="addressStr">
          </el-table-column>
          <el-table-column align='center' label="联系电话" prop="phone">
          </el-table-column>
          <el-table-column align='center' label="备注" prop="description">
            <template slot-scope="scope">
              <p>{{ scope.row.description || scope.row.description == 0 ? scope.row.description : '--' }}</p>
            </template>
          </el-table-column>
          <el-table-column align='center' label="创建时间" prop="createTimeStr">
          </el-table-column>
          <el-table-column align='center' label="操作" fixed="right" width="220">
            <template slot-scope="scope">
                <el-button size="mini" @click="handleOrder(scope.row)">订单</el-button>
                <el-button size="mini" @click="handleAdd(scope.row)">编辑</el-button>
                <el-button size="mini" @click="handleDel(scope.row)">删除</el-button>
            </template>
          </el-table-column>
          </el-table>
      </div>
      <!--分页-->
      <Pagination v-bind:child-msg="searchInfo" @callFather="callFather"></Pagination>
      <!-- 新增、编辑 -->
      <el-drawer :title="row ? '编辑' : '新增'"
        :visible.sync="addDrawer"
        size="50%">
        <add v-if="addDrawer" @close="handleClose"  ref='newForm' :row="row"></add>
      </el-drawer>
      <!-- 详情 -->
      <el-drawer title="详情"
        :visible.sync="detailDrawer"
        size="50%">
        <detail v-if="detailDrawer" @close="handleClose"  ref='newForm' :row="row"></detail>
      </el-drawer>
    </section>
  </template>
  
  <script>
  import Vue from 'vue';
    import { getMerchantList,delMerchant,getParentTree } from '@/api/public.js';
    import Pagination from '@/components/Pagination.vue';
    import add from './add.vue';
    import detail from './detail.vue';
    import { timeChange } from '@/api/publicFun.js';
    export default {
        data(){
            return {
              tableList:[], // 表格数据
              searchInfo:{ // 搜索条件
                pageNo:1,
                pageSize:10,
                total:0,
                param:{
                    name:'',
                }
              },
              addDrawer:false, // 控制侧边新增弹框是否显示
              detailDrawer:false, // 控制侧边详情弹框是否显示
              row:null, // 当前操作数据
              cityOptions:[], // 省市区联动数据
            }
        },
        mounted(){
            this.init();
        },
        components:{
            Pagination,
            add,
            detail,
        },
        methods:{
            // 获取表格数据
            async init(){
              await getMerchantList(this.searchInfo).then(res=>{
                if(res.success){
                  this.tableList = res.data.list;
                  this.searchInfo.total = res.data.total;
                  this.tableList.forEach( async( element,index )=> {
                    element.addressStr = '';
                    element.createTimeStr = element.createTime ? timeChange(element.createTime,'seconds') : '--';
                    await this.getParentPca(element.regionId).then( addressRes =>{
                      element.addressStr = addressRes + element.address;
                      Vue.set(this.tableList, index, element);
                    })
                  });
                }else{
                  this.tableList = [];
                  this.searchInfo.total = 0;
                  this.$message({
                    message:res.msg,
                    type:'fail',
                  })
                }
              })
            },
            // 搜索事件
            handleSearch(type){
              if(type == 'clear'){ // 清空搜索条件
                this.searchInfo.param = {
                  name:"",
                }
              }
              this.searchInfo.pageNo = 1;
              this.init();
            },
            // 省市区：通过子级id获取父级id
            getParentPca(id){
              let param = new URLSearchParams();
              param.append("param", id);
              return new Promise((resolve) => {
                getParentTree(param).then( res =>{
                  if(res.success){
                    let all = res.data;
                    let address = this.digui(all,[],"");
                    resolve(address);
                  }
                })
              })
            },
            // 递归
            digui(obj,regionArr,address){
              regionArr.unshift(obj.id);
              address = obj.name + address;
              if(obj.parent){
                return this.digui(obj.parent,regionArr,address);
              }else{
                return address;
              }
            },
            // 前往订单列表
            handleOrder(row){
              this.$router.push({
                path:'/commercialOwner/order',
                query:{
                  merchantId:row.id,
                }
              })
            },
            // 打开新增、编辑
            handleAdd(row){
              this.row = row;
              this.addDrawer = true
            },
            // 打开详情
            handleDetail(row){
              this.row = row;
              this.detailDrawer = true;
            },
            // 关闭侧边弹框
            handleClose(){
              this.addDrawer = false;
              this.detailDrawer = false;
              this.init();
            },
            // 删除
            handleDel(row){
              this.$confirm('确认删除该数据吗？').then( async () => {
                await delMerchant({param:row.id}).then(res=>{
                  if(res.success){
                    this.$message({
                      message:'操作成功',
                      type:'success'
                    });
                  }else{
                    this.$message({
                      message:res.msg,
                      type:'fail'
                    });
                  }
                })
                this.init();
              })
            },
            // 分页插件事件
            callFather(param){
              this.searchInfo.pageNo = param.currentPage;
              // this.init();
            },
        }
    }
  </script>
  
  <style scoped lang="scss">
    @import "@/static/default";
  /* 内容 */
  .container{
      background-color: #fff !important;
      padding:0 32px;
      .toolbar{
        background: #fff;
        width:calc(100% + 64px);
        padding:16px 32px 26px 0;
        margin-left:-32px;
      }
  }
  </style>